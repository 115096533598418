import React, { useContext, useEffect } from "react"

import Layout from "src/components/Layout"
import Seo from "../components/seo"

import Hero from "src/components/Sections/Landing/Hero"
import Services from "src/components/Sections/Landing/Services"
import Statistics from "src/components/Sections/Landing/Statistics"
import Projects from "src/components/Sections/Landing/Projects"
import { Partners } from "src/components/Sections/Landing/Partners"
import ThemeContext, { Themes } from "src/theme/context"
import gsap, { Power1 } from "gsap"
const IndexPage = ({ location = {} }) => {
  const { changeTheme } = useContext(ThemeContext)
  const { state = {} } = location
  const scrollTo = state?.scrollTo || ""
  const locationKey = state?.key || ""

  useEffect(() => {
    changeTheme(Themes.default)
  }, [])

  useEffect(() => {
    if (window && !!scrollTo && !!locationKey) {
      gsap.to(window, {
        duration: 2,
        ease: Power1.easeInOut,
        scrollTo: { y: `#${scrollTo}`, offsetY: 40 },
        delay: 0.3,
      })
    }
  }, [scrollTo, locationKey])
  return (
    <Layout>
      <Seo title="Blesu" description="We build digital products." />

      <Hero />
      <Services />
      <Statistics />
      <Projects />
      <Partners />
    </Layout>
  )
}

export default IndexPage
