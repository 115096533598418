import React, { useContext, useEffect, useRef } from "react"
import Typography from "src/components/Typography"
import * as styles from "./Partners.module.scss"
import ThemeContext, { Themes } from "src/theme/context"
import gsap from "gsap"
import LogoGrid from "./LogoGrid"
import ContactForm from "./ContactForm"

export function Partners() {
  const { changeTheme } = useContext(ThemeContext)
  const sectionRef = useRef(null)
  const headerRef = useRef(null)
  const paragraphRef = useRef(null)

  useEffect(() => {
    const toggleTheme = ({ direction }) => {
      if (direction === 1) {
        changeTheme(Themes.secondary)
      }

      if (direction === -1) {
        changeTheme(Themes.default)
      }
    }

    const themeAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "10% 60%",
        end: "+=0%",
        onEnter: toggleTheme,
        onEnterBack: toggleTheme,
        onLeave: toggleTheme,
        onLeaveBack: toggleTheme,
        once: false,
      },
    })

    const headerAnimation = gsap
      .timeline({
        scrollTrigger: {
          trigger: headerRef.current,
          start: "top 80%",
          end: "+=0",
          once: true,
        },
      })
      .from(headerRef.current, {
        y: 50,
        opacity: 0,
        duration: 0.5,
        ease: "power2.out",
      })

    const paragraphAnimation = gsap
      .timeline({
        scrollTrigger: {
          trigger: paragraphRef.current,
          start: "top 77%",
          end: "+=0",
          once: true,
        },
      })
      .from(paragraphRef.current, {
        y: 60,
        opacity: 0,
        duration: 0.5,
        delay: 0.1,
        ease: "power2.out",
      })

    const logoAnimation = gsap
      .timeline({
        scrollTrigger: {
          trigger: ".animated-partner-logo",
          start: "top 75%",
          end: "+=0",
          once: true,
        },
      })
      .from(".animated-partner-logo", {
        opacity: 0,
        duration: 0.6,
        delay: 0.35,
        stagger: 0.35,
        ease: "power2.out",
      })

    const contactAnimation = gsap
      .timeline({
        scrollTrigger: {
          trigger: ".animated-contact-form",
          start: "top 73%",
          end: "+=0",
          delay: 0.5,
          once: true,
        },
      })
      .fromTo(
        ".animated-contact-form",
        {
          opacity: 0,
          duration: 0.65,
          delay: 0.35,
          yPercent: 15,
          scale: 0.95,
          ease: "power2.out",
        },
        {
          opacity: 1,
          yPercent: 0,
          scale: 1.05,
        }
      )

    return () => {
      themeAnimation.kill()

      paragraphAnimation.kill()
      headerAnimation.kill()
      logoAnimation.kill()
      contactAnimation.kill()
    }
  }, [])

  return (
    <section ref={sectionRef} className={styles.PartnersWrapper}>
      <Typography ref={headerRef} variant="h2">
        It’s good to have a friend
      </Typography>
      <Typography
        ref={paragraphRef}
        className={styles.CustomParagraph}
        variant="p"
      >
        We are partner with top companies to always meet your requirements.
      </Typography>

      <LogoGrid />

      <ContactForm />
    </section>
  )
}
