import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import Typography from "src/components/Typography"
import * as styles from "./Hero.module.scss"
import Lottie from "lottie-web"
import { StaticImage } from "gatsby-plugin-image"

export default function Hero() {
  const containerRef = useRef()
  const [animationVisible, setAnimationVisible] = useState(false)

  useEffect(() => {
    const lottieAnimation = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/lottie.json",
      // renderer: "canvas",
    })

    lottieAnimation.addEventListener("DOMLoaded", function (e) {
      setAnimationVisible(true)
    })
    return () => {
      lottieAnimation.destroy()
    }
  }, [])
  return (
    <section className={styles.HeroWrapper}>
      <article>
        <Typography variant="h1">
          <span>We build</span>
          <br />
          <span> digital products</span>
        </Typography>
        <Typography variant="p">
          Boost your business with technology.
        </Typography>
        <Link to="/contact" state={{ scrollTo: "" }}>
          <button>Let's talk</button>
        </Link>
      </article>
      <div className={styles.ImageWrapper}>
        <div
          ref={containerRef}
          style={{
            opacity: animationVisible ? 1 : 0,
            transition: "opacity 0.6s ease 0.2s",
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className={styles.GatsbyImageWrapper}
            style={{
              opacity: animationVisible ? 1 : 0,
            }}
          >
            <StaticImage
              src="../../../../images/Layers.png"
              alt="Layers illustration"
              layout="constrained"
              placeholder="none"
              quality={100}
              // width={900}
              // height={900}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
