import React, { useContext, useEffect, useRef } from "react"
import Typography from "src/components/Typography"
import ThemeContext, { Themes } from "src/theme/context"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import * as styles from "./Statistics.module.scss"
import Grid from "./Grid"
import clsx from "clsx"

const paragraphCopy =
  "From winning the biggest European hackathons, through creating startup MVPs to delivering projects for Warsaw Stock Exchange companies. On our way, we gained experience in plenty of projects and had a chance to work with a variety of users."

export function Statistics() {
  const sectionRef = useRef(null)
  const headerRef = useRef(null)
  const { changeTheme } = useContext(ThemeContext)

  const toggleTheme = ({ direction }) => {
    if (direction === 1) {
      changeTheme(Themes.secondaryDark)
    }

    if (direction === -1) {
      changeTheme(Themes.default)
    }
  }

  useEffect(() => {
    const themeAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 60%",
        end: "+=0",
        onEnter: toggleTheme,
        onLeaveBack: toggleTheme,
        once: false,
      },
    })
    const headerAnimation = gsap
      .timeline({
        scrollTrigger: {
          trigger: headerRef.current,
          start: "top 80%",
          end: "+=0",
          once: true,
        },
      })
      .from(headerRef.current, {
        y: 100,
        opacity: 0,
        duration: 0.5,
        ease: "power2.out",
      })

    let textAnimation
    ScrollTrigger.matchMedia({
      "(min-width: 871px)": function createDesktopTextAnimation() {
        textAnimation = gsap
          .timeline({
            scrollTrigger: {
              trigger: ".animated-span",
              start: "top 75%",
              end: "+=0",
              once: true,
            },
          })
          .from(".animated-span", {
            y: 30,
            opacity: 0,
            duration: 0.65,
            ease: "power2.out",
            stagger: 0.033,
            delay: 0.35,
          })
      },

      "(max-width: 870px)": function createMobileTextAnimation() {
        textAnimation = gsap
          .timeline({
            scrollTrigger: {
              trigger: ".animated-span",
              start: "top 75%",
              end: "+=0",
              once: true,
            },
          })
          .from(".animated-span-wrapper", {
            y: 30,
            opacity: 0,
            duration: 0.8,
            delay: 0.4,
            ease: "power2.out",
          })
      },
    })

    const gridAnimation = gsap
      .timeline({
        scrollTrigger: {
          trigger: ".animated-statistics-element",
          start: "top 70%",
          end: "+=0",
          once: true,
        },
      })
      .from(".animated-statistics-element", {
        opacity: 0,
        duration: 0.6,
        ease: "power1.out",
        stagger: 0.3,
        delay: 0.8,
      })

    return () => {
      themeAnimation.kill()
      headerAnimation.kill()
      textAnimation?.kill()
      gridAnimation.kill()
    }
  }, [])

  return (
    <section ref={sectionRef} className={styles.StatisticsWrapper}>
      <Typography ref={headerRef} variant="h2">
        Projects that count
      </Typography>
      <div className={clsx(styles.TextContainer, "animated-span-wrapper")}>
        {paragraphCopy.split(" ").map((str, index) => (
          <Typography
            key={index + str}
            variant="span"
            className={"animated-span"}
          >
            {str}
            &nbsp;
          </Typography>
        ))}
      </div>

      <Grid />
    </section>
  )
}
