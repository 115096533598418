import React, { useContext } from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as styles from "./LogoGrid.module.scss"
import ThemeContext, { Themes } from "src/theme/context"

const getShouldInvertColors = theme => {
  switch (theme) {
    case Themes.secondaryDark:
    case Themes.dark:
    case Themes.secondary:
      return false
    default:
      return true
  }
}

const LogoGrid = () => {
  const { theme } = useContext(ThemeContext)

  const shouldInvertColors = getShouldInvertColors(theme)

  return (
    <div className={styles.Grid}>
      <StaticImage
        src="../../../../images/microsoft.png"
        alt="Microsoft logo"
        layout="constrained"
        className="animated-partner-logo"
        placeholder="none"
        imgStyle={{ filter: `invert(${shouldInvertColors ? 1 : 0})` }}
        width={300}
      />
      <StaticImage
        src="../../../../images/tpay.png"
        alt="tPay logo"
        layout="constrained"
        placeholder="none"
        imgStyle={{ filter: `invert(${shouldInvertColors ? 1 : 0})` }}
        className="animated-partner-logo"
        width={300}
      />
    </div>
  )
}

export default LogoGrid
