import React, { useContext } from "react"
import ThemeContext, {
  getThemeCategory,
  ThemeCategories,
} from "src/theme/context"
import clsx from "clsx"

import * as styles from "./Arrow.module.scss"

const SVGArrow = ({ direction }) => (
  <svg
    width="11"
    height="19"
    viewBox="0 0 11 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: direction === "right" ? "rotate(180deg)" : "rotate(0deg)",
    }}
    className="arrow-svg"
  >
    <path
      d="M9.33334 17.1667L1.66668 9.50008L9.33334 1.83342"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ArrowButton = ({
  direction = "left",
  onClick = () => {},
  style = {},
  className = "",
}) => {
  const { theme } = useContext(ThemeContext)

  const localClassName =
    getThemeCategory(theme) === ThemeCategories.dark
      ? styles.LightArrow
      : styles.DarkArrow

  return (
    <div
      style={{
        ...style,
      }}
      className={clsx(localClassName, className)}
      onClick={onClick}
    >
      <SVGArrow direction={direction} />
    </div>
  )
}
