import React, { useEffect, useRef, useContext } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import ThemeContext, { Themes } from "src/theme/context"

import Typography from "src/components/Typography"
import * as styles from "./Services.module.scss"
import clsx from "clsx"

const firstQuery = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/services/" } }) {
      edges {
        node {
          name
          id
          childImageSharp {
            gatsbyImageData(quality: 80, width: 300, placeholder: NONE)
          }
        }
      }
    }
  }
`

const contentArray = [
  {
    logo: "Website",
    header: "Custom Development",
    link: "/services",
    scrollTo: "customDevelopment",
    paragraph:
      "We listen to your ideas and build reliable digital products from scratch, which are created especially for your business. Our engineers and executive team Focus on making your idea a reality. You can trust us and we deliver.",
  },
  {
    logo: "Users",
    header: "Business Applications",
    link: "/services",
    scrollTo: "businessApplications",
    paragraph:
      "At Blesu we design, build, ship and scale beautiful tailor-made apps to streamline processes in your organization.",
  },
  {
    logo: "Pencil",
    header: "Product Design",
    link: "/services",
    scrollTo: "productDesign",
    paragraph:
      "We create products in cooperation with users, which ensures great and usable design of the product that achieves your business goals.",
  },
  {
    logo: "Settings",
    header: "Digital Transformation",
    link: "/services",
    scrollTo: "digitalTransformation",
    paragraph:
      "The organisational change of approach aiming to automate and digitalise processes. This will enable your organisation to make data driven decisions and provide more value to customers while boosting effectiveness of work.",
  },
]

const getGridItemClass = theme => {
  switch (theme) {
    case Themes.secondaryDark:
    case Themes.dark:
      return styles.ItemDarkTheme
    default:
      return styles.ItemLightTheme
  }
}

const ArrowButton = () => (
  <button>
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="18" cy="18.5" rx="18" ry="18.5" fill="url(#paint0_radial)" />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.5564 -34.225) rotate(129.496) scale(78.3155 729.385)"
        >
          <stop stopColor="#3038D0" />
          <stop offset="0.0989583" stopColor="#323AD5" />
          <stop offset="1" stopColor="#464FFC" />
        </radialGradient>
      </defs>
      <g transform="translate(14 10.5)">
        <path
          d="M1.66666 1.16211L7.33332 6.98618L1.66666 12.8103"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
    <span>LEARN MORE</span>
  </button>
)

export default function Services() {
  const data = useStaticQuery(firstQuery)
  const { edges: images = [] } = data.allFile

  const { theme } = useContext(ThemeContext)

  const itemsWrapperRef = useRef(null)
  const sectionRef = useRef(null)
  const headerRef = useRef(null)

  useEffect(() => {
    const headerAnimation = gsap
      .timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 70%",
          end: "+=0",
          once: true,
        },
      })
      .from(headerRef.current, {
        y: 70,
        opacity: 0,
        duration: 0.5,
        ease: "power1.out",
      })

    let itemsAnimation

    ScrollTrigger.matchMedia({
      "(max-width: 870px)": function createMobileAnimation() {
        itemsAnimation = gsap
          .timeline({
            scrollTrigger: {
              trigger: ".animated-services-wrapper",
              start: "top 70%",
              end: function calculateWrapperHeight() {
                return `+=${itemsWrapperRef.current?.offsetHeight}` || "+=100%"
              },
              scrub: true,
            },
          })
          .from(".animated-service", {
            opacity: 0,
            y: 60,
            duration: 0.4,
            ease: "power2.out",
            stagger: 0.35,
          })
      },
      "(min-width: 871px)": function createDesktopAnimation() {
        itemsAnimation = gsap
          .timeline({
            scrollTrigger: {
              trigger: ".animated-service",
              start: "top 60%",
              end: "+=150",
              once: true,
            },
          })
          .from(".animated-service", {
            opacity: 0,
            y: 40,
            duration: 0.7,
            ease: "power2.out",
            stagger: 0.35,
            delay: 0.3,
          })
      },
    })

    return () => {
      headerAnimation.kill()
      itemsAnimation?.kill()
    }
  }, [])

  const ItemClassName = getGridItemClass(theme)

  return (
    <section ref={sectionRef} className={styles.ServicesWrapper}>
      <Typography ref={headerRef} variant="h2">
        We provide world-class services
      </Typography>
      <div
        ref={itemsWrapperRef}
        className={clsx(styles.ServicesGrid, "animated-services-wrapper")}
      >
        {contentArray.map(({ logo, header, paragraph, link, scrollTo }) => (
          <div key={header} className="animated-service">
            <Link
              to={link}
              state={{ scrollTo }}
              className={clsx(ItemClassName)}
            >
              <div className={styles.IconContainer}>
                <GatsbyImage
                  image={getImage(
                    images.find(({ node }) => node.name === logo)?.node
                      ?.childImageSharp
                  )}
                  alt={"icon"}
                  width={150}
                  height={150}
                  layout="constrained"
                />
              </div>
              <div className={styles.GridItemTextWrapper}>
                <h3>{header}</h3>
                <p>{paragraph}</p>
                <ArrowButton />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}
