import React from "react"
import * as styles from "./Grid.module.scss"

const copyContent = [
  { header: "4", paragraph: "years on the market" },
  { header: "30+", paragraph: "web projects" },
  { header: "25 000+", paragraph: "active end-users and counting" },
]

function Grid() {
  return (
    <div className={styles.GridWrapper}>
      {copyContent.map(({ header, paragraph }, index) => (
        <div key={`${index}${header}`} className="animated-statistics-element">
          <span className={styles.Bold}>{header}</span>
          <span className={styles.Thin}>{paragraph}</span>
        </div>
      ))}
    </div>
  )
}

export default Grid
