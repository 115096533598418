import React, { useContext, useRef, useEffect } from "react"
import Slider from "react-slick"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Typography from "src/components/Typography"
import ThemeContext, { Themes } from "src/theme/context"
import * as styles from "./Projects.module.scss"
import { ArrowButton } from "./Arrow"
import clsx from "clsx"

const firstQuery = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/projects/" } }) {
      edges {
        node {
          name
          id
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: NONE)
          }
        }
      }
    }
  }
`

const content = [
  // {
  //   id: "DINO_PROJECT",
  //   image: "dino",
  //   copy:
  //     "App that allows centrally located managers to schedule and delegate tasks to a team outside the company headquarters. The system is suited to carry out daily store operations and to help specialists plan and execute sales strategy. The ecosystem consists of an application running in a web browser and a mobile application for iOS and Android phones. Implementation of this tool resulted in a more efficient workforce that knows exactly what task they should focus on. At the same time managers can make informed decisions thanks to insights based on data from the stores.",
  // },
  {
    id: "WIELTON_PROJECT",
    image: "wielton",
    copy:
      "Wielton is the top 3 truck trailer producer in Europe listed on the Warsaw Stock Exchange. Our system connects every employee with each other as well as an employer. We strongly focused on enabling production and non-office workers to stay connected with themselves, employer and to create a community around it. We delivered a mobile app with content curated for every employee. On top of that, we build a web app for the HR department to manage content, control the training process and ensure flexible top-down communication.",
  },
  {
    id: "SUSHI_PROJECT",
    image: "sushi",
    copy:
      "Pracownia Sushi is one of the biggest restaurants in Poland delivering hundreds of orders every day. We help this company in their digitalisation process and automation of order management, especially in delivery. System delivered by Blesu is responsible for the whole end-to-end process, including obtaining order, payment processing, resources allocation, production scheduling and delivery planning. We also built a new, fully integrated mobile-first website on top of that. All of this has resulted in a 3x increase in order processing capacity per hour.",
  },
  {
    id: "PGNiG_PROJECT",
    image: "pgnig",
    copy:
      "There is no need to introduce PGNiG in Poland - one of the biggest energy providers reaching millions of Polish houses. Managing documents within such a big and disturbed organization is a complex process that also needs to be done according to all of the GDPR rules. To solve issues related to this case we developed a durable medium solution to provide the only source of truth related to PII documents flow. We assured process proofing and certification of documents immutability by integrating our solution with a document management system.",
  },
  {
    id: "ASTORYA_PROJECT",
    image: "astorya",
    copy:
      "Astorya is a venture capital firm focused mainly on InsurTech companies across Europe. After years of investing and working with insurance companies as a VC, Astorya decided to use their know-how and build their own product - the search engine for insurance and banking technologies in Europe. Blesu developed a web platform that connected with Atorya’s engine to help brands build their investment pipelines, innovation roadmaps, or run tech RFPs. Powered by the best data our software is a part of the largest search engine for insurance & banking technologies in Europe.",
  },
  {
    id: "CUBIC_PROJECT",
    image: "cubic",
    copy:
      "Cubic Inch provides services of additive manufacturing. They deliver high quality 3D printed products within a short timeframe. To maintain high quality and deliver orders as quickly as possible, Cubic Inch automated production flow with the help of custom made software. Blesu delivered a digital hub consisting of a dashboard and background integration of all other systems used in their organization such as production scheduling, CRM, invoice management and delivery handling.",
  },
  {
    id: "DENTEC_PROJECT",
    image: "dentec",
    copy:
      "Dentec designs and implements production lines in modern factories. They specialise in pneumatics technology. Together we created the Vacupredict - an Industrial IoT device equipped with sensors used to constantly read and analyse production line parameters. Vacupredict delivers a prediction model that allows plant managers to identify a risk of production line malfunction before it actually happens and to effectively schedule maintenance tasks minimising idle time of the machine. Blesu engineers delivered the software for the project including the ML based model, web app to manage vacupredicts and supporting cloud infrastructure.",
  },
  {
    id: "FESTO_PROJECT",
    image: "festo",
    copy:
      "Festo is a leading brand of German multinational control and industrial automation company. With the innovative approach for a human-machine interface, we build an augmented reality used to operate a robot following industry 4.0 standards. Our application was used for presentation purposes of a complete set of device capabilities with AR. With the help of the iPad application is showing a real-time and contextual presentation of machine status.",
  },
]
export function Projects() {
  const data = useStaticQuery(firstQuery)
  const { edges: images = [] } = data.allFile

  const { changeTheme } = useContext(ThemeContext)
  const sectionRef = useRef(null)
  const headerRef = useRef(null)
  const sliderRef = useRef(null)
  const sliderWrapperRef = useRef(null)

  function onNextClick() {
    sliderRef.current.slickNext()
  }

  function onPrevClick() {
    sliderRef.current.slickPrev()
  }

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: false,
    arrows: false,
  }

  useEffect(() => {
    const toggleTheme = ({ direction }) => {
      if (direction === 1) {
        changeTheme(Themes.default)
      }

      if (direction === -1) {
        changeTheme(Themes.secondaryDark)
      }
    }

    const themeAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 60%",
        end: "+=0",
        onEnter: toggleTheme,
        onEnterBack: toggleTheme,
        once: false,
      },
    })

    const itemsAnimation = gsap
      .timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 85%",
          end: "+=0",
          once: false,
        },
      })
      .from(headerRef.current, {
        y: 50,
        opacity: 0,
        duration: 0.4,
        ease: "power2.out",
      })
      .from(".animated-slider-content", {
        y: 30,
        opacity: 0,
        duration: 0.45,
        ease: "power2.out",
      })
      .from(".animated-slider-buttons", {
        opacity: 0,
        y: 30,
        duration: 0.4,
        ease: "power2.out",
      })

    return () => {
      themeAnimation.kill()
      itemsAnimation.kill()
    }
  }, [])

  return (
    <section
      id="projects-section"
      ref={sectionRef}
      className={styles.ProjectsWrapper}
    >
      <Typography ref={headerRef} variant="h2">
        Some of our projects
      </Typography>

      <div className={styles.SliderWrapper} ref={sliderWrapperRef}>
        <div className={clsx(styles.Buttons, "animated-slider-buttons")}>
          <ArrowButton onClick={onPrevClick} />
          <ArrowButton
            direction="right"
            onClick={onNextClick}
            className={styles.RightArrow}
          />
        </div>

        <Slider ref={sliderRef} {...settings} className={styles.Slider}>
          {content.map(({ id, image, copy }) => (
            <div key={id}>
              <article
                className={clsx(styles.SliderItem, "animated-slider-content")}
              >
                <div className={styles.ImageContainer}>
                  <GatsbyImage
                    image={getImage(
                      images.find(({ node }) => node.name === image)?.node
                        ?.childImageSharp
                    )}
                    alt={"company logo"}
                    width={150}
                    height={150}
                    imgStyle={{ maxHeight: "100%" }}
                    style={{ maxHeight: "100%" }}
                    layout="constrained"
                  />
                </div>
                <Typography variant="p" className={styles.CustomParagraph}>
                  {copy}
                </Typography>
              </article>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
